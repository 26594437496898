export const anonymousPaths = {
    landing: '/',
    login: '/login',
    regularLogin: '/regular-login',
}

export const playerPaths = {
    home: '/home',
    chatList: '/chat',
    chatDetails: '/chat/:chatId/*',
    userSettings: '/settings',
}

export const chatPaths = {
    matchdayList: '/matchday',
    manigaList: '/maniga',
    users: '/users',
    settings: '/settings',
    priorityPlayers: '/priority-players',
    stats: '/stats',
    matchdayDetails: '/matchday/:date/*'
}

export const adminPaths = {
    home: '/admin',
    chats: '/admin/chat',
    matchdays: 'admin/chat/:chatId/matchday',
    matchdayVotes: '/admin/chat/:chatId/matchday/:matchdayId/votes'
}

